<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body>
            <b-card-text>

              <b-row>
                <b-col lg="4" cols="12">
                  <template v-if="noticeDetails?.title">
                  <h5 class="text-capitalize">Notice Title</h5>
                      <div>
                        <b-card-text> {{ noticeDetails?.title }}</b-card-text>
                      </div>
                      <br>
                      <p>Published Date: {{ formatDate(noticeDetails?.created_at) }}</p>
                    </template>

                    <template v-if="noticeRecieverOptoins[noticeDetails?.type - 1]?.name">
                    <h5 class="text-capitalize mt-2">Notice Type</h5>
                      <div>
                        <b-card-text> {{ noticeRecieverOptoins[noticeDetails?.type - 1]?.name }}</b-card-text>
                      </div>
                    </template>

                    
                </b-col>
                <b-col lg="6" cols="12">
                  <template v-if="noticeDetails.description">
                  <h5 class="text-capitalize">Notice Description</h5>
                    <div class="ql-editor">
                      <div v-html="noticeDetails.description"></div>
                    </div>
                  </template>
                </b-col>
              </b-row>

            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body>
            <h1>Recipient list</h1>
              <template>
                <b-table 
                responsive="sm" 
                :items="noticeRecipients"
                :fields="tableFields"
                />
              </template>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </section>
  
</template>

  <script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { required, max, size, ext } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  LEAD_ACTIVITY_ACCESS,
  LEAD_ACTIVITY_CREATE,
  LEAD_ACTIVITY_EDIT,
  LEAD_ACTIVITY_DELETE,
} from "@/helpers/permissionsConstant";
export default {
  name: "NoticeDetailsView",
  components: {
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BCardBody,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LEAD_ACTIVITY_ACCESS,
      LEAD_ACTIVITY_CREATE,
      LEAD_ACTIVITY_EDIT,
      LEAD_ACTIVITY_DELETE,
      noticeId: this.$route.params.id,
      noticeDetails: "",
      noticeRecipients: [],
      noticeRecieverOptoins: [
        {
          name: "Notice For Divisions",
          value: 1,
        },
        {
          name: "Notice For Division Heads",
          value: 2,
        },
        {
          name: "Notice For Departments",
          value: 3,
        },
        {
          name: "Notice For Departments Heads",
          value: 4,
        },
        {
          name: "Notice For Employees",
          value: 5,
        },
        {
          name: "Notice For Clients",
          value: 6,
        },
        {
          name: "Custom Notice",
          value: 7,
        },
      ],

      tableFields: [
        { key: "seNo", label: "#" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "department", label: "Department" },
        { key: "designation", label: "Designation" },
      ],
    
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const id = this.$route.params.id;
      const resNoticeDetails = await this.$api.get(
        `api/notices/${id}?include=noticeSummaries`
      );

      this.noticeDetails = resNoticeDetails?.data?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }

    try {
      const id = this.$route.params.id;
      const resNoticeRecipients = await this.$api.get(
        `/api/notices/${id}/notice-recipient-details`
      );

      this.noticeRecipients = (resNoticeRecipients?.data?.data?.notice_summaries || []  
      ).map((item, index) => {

        
        let name = item?.user?.name;
        let email = item?.user?.email;
        let department = item?.user?.department?.name;
        let designation = item?.user?.designation?.name;

        return {
          seNo: (index + 1).toString(), 
          name: name,
          email: email,
          department: department,
          designation: designation,
        };
      });


    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);
    },
  },
};
</script>

<style lang="scss">
blockquote {
  border-left: 4px solid #ccc !important;
  padding-left: 16px !important;
}

@import "@core/scss/vue/libs/quill.scss";
</style>
